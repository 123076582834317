/* Dependencies
 * function getUrlPageNameFormatted() from hubspot-pagename.js
*/

const HUBSPOT_PORTAL_ID = '8180620'
const MAIN_FORM_ID = '80f891ab-4f8d-450b-a588-6569de1585fa'
const SUBSCRIBE_URL = 'https://api.hsforms.com/submissions/v3/integration/submit/' + HUBSPOT_PORTAL_ID + '/' + MAIN_FORM_ID
const FORM_URL = 'https://forms.hsforms.com/embed/v3/form/' + HUBSPOT_PORTAL_ID + '/' + MAIN_FORM_ID + '/json?hutk=';

function _setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }

    const valueOutput = typeof value == "object" ? JSON.stringify(value) : value;
    document.cookie = name + "=" + (valueOutput || "") + expires + "; path=/";
}

function _getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');

    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}

var hubspotIntegration = function sendSubscribe(subscription, layerData, callback) {
    var contentPath = window.location.pathname;

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    var firstNameNode = subscription.find(function (tree) {
        return tree.name == "firstname";
    });

    var gmvNameNode = subscription.find(function (tree) {
        return tree.name == "gmv";
    });

    var legal = subscription.find(function (tree) {
        return tree.name == "LEGAL"
    });

    function sanitizeFirstName(node) {
        return node.value.split(' ')[0]
    }

    function sanitizeLastName(node) {
        var fullname = node.value.split(' ')
        var pre = []

        for (var i = 1; i < fullname.length; i++) {
            pre.push(capitalizeFirstLetter(fullname[i]))
        }

        var lastname = pre.join(' ')

        return {
            "name": "lastname",
            "value": lastname
        }
    }

    // event for facebook conversion API
    if(contentPath.split('/')[1] === "next"){
        facebookTracking('Registration Business');
    }

    // get utms values from url
    function getUtmParams () {
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);

        var utms = {
            source: urlParams.get('utm_source'),
            medium: urlParams.get('utm_medium'),
            campaign: urlParams.get('utm_campaign'),
            content: urlParams.get('utm_content')
        }

        return utms;
    }


    // CREATE AND POPULATE SUBSCRIBE OBJECT

    var subscribeObject = {}

    if (firstNameNode) {
        subscription.push(sanitizeLastName(firstNameNode))
        firstNameNode.value = sanitizeFirstName(firstNameNode)
    }

    subscription = subscription.filter(function( obj ) {
        return obj.name !== "LEGAL";
    });

    subscribeObject.fields = subscription;

    // get utms values from url
    var utms = getUtmParams();
    subscribeObject.fields.push({name: "utm_source", value: utms.source ? utms.source : ''});
    subscribeObject.fields.push({name: "utm_medium", value: utms.medium ? utms.medium : ''});
    subscribeObject.fields.push({name: "utm_campaign", value: utms.campaign ? utms.campaign : ''});
    subscribeObject.fields.push({name: "utm_content", value: utms.content ? utms.campaign : ''});


    subscribeObject.context = {
        "hutk": _getCookie('hubspotutk'),
        "pageUri": window.location.href,
        "pageName": getUrlPageNameFormatted() + document.title
    }

    // LEGAL OBJECT
    if (legal != undefined ) {
        var legalInfo = legal.value == "true" ? true : false,
            consent = {};

        consent.consent = {
            "consentToProcess": legalInfo,
            "text": "User is giving us consent to send messages",
            "communications": [
                {
                    "value": legalInfo,
                    "subscriptionTypeId": 999,
                    "text": "Consent for sending marketing messages"
                }
            ]
        }

        if(legalInfo == true) {
            subscribeObject.legalConsentOptions = consent;
        }
    }

    if (layerData) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'subscribeBusiness',
            'gmv': gmvNameNode ? gmvNameNode.value : null
        });
    }

    async function postData(url = '', data = {}) {
        const res = await fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!res.ok) {
            var message = 'an error has ocurred: ' + res.status;
            amplitude_log_event('hubspot_error', {
                pageName: getUrlPath()
            })
            throw new Error(message);
        }

        return await res.json();
    }

    postData(SUBSCRIBE_URL, subscribeObject)
    .then(data => {
        if (callback) {
            callback();
        }
    })
    .catch((error) => {
        console.error(error);
    });

};

var hubspotCustomEvents = function sendEvents(eventId) {

    if (typeof eventId === 'string' ) {
         var _hsq = window._hsq = window._hsq || [];

        _hsq.push(["trackEvent", {
            id: eventId
        }]);

        console.warn('Event ' + eventId + ' was fired')
    } else {
        console.warn('eventID is invalid')
    }
}