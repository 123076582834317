function getUrlPageNameFormatted() {
  var contentPath = window.location.pathname;
  var pathAsArray = contentPath.split('/');
  var path = pathAsArray[1].includes('mx', 'co') ? pathAsArray[2] : pathAsArray[1],
      prefix = '',
      idContent = contentPath;

  switch (path) {
      case 'blog':
          prefix = 'blog';
          idContent = document.querySelector('body').dataset.postSlug != "undefined" ? document.querySelector('body').dataset.postSlug : '';
          break;

      case 'empresarial':
          prefix = 'business';
          break;

      case 'next':
          prefix = 'next';
          break;

      case 'migre-sua-loja-virtual':
      case 'migrar-tienda-en-linea':
          prefix = 'migration';
          break;

      case 'ferramentas':
      case 'herramientas':
          prefix = 'tools';
          break;

      default:
          prefix = ''
          break;
  }

  var pre = '<' + prefix + '> ',
      prefixIdentifier = pre + (idContent =! '' ? '<id: ' + idContent + '> ' : '');

  return prefixIdentifier;
}