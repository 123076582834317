/* Dependencies
 * function getUrlPageNameFormatted() from hubspot-pagename.js
*/

const FORM_STEP_COOKIE = 'hubspot_form_step';
const USER_DATA_COOKIE = 'hubspot_user_data';
const FORM_WRAPPER_ID = "#hubspot-form";
const HUBSPOT_FORM_SUBMIT_EVENT = new CustomEvent('onHubspotFormSubmit');

const HUBSPOT_FORM_ID = {
  "pt_BR": "8250e9d5-b019-4727-948f-0bb2263518db",
  "es_AR": "e785bf7a-8e6e-4dd8-ae64-c1ebe16463fe",
  "es_MX": "9e92c77a-d06b-43c5-b3c6-98669d1fa5ca",
};

const HUBSPOT_FORM_EMBED = {
  currenStep: () => {
    return _getCookie(FORM_STEP_COOKIE);
  },

  hasFinished() {
    return this.currenStep() == 'finish'
  },

  generateForm(formNodeElement = FORM_WRAPPER_ID) {
    // Generate hubspot forms DOM script
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: HUBSPOT_PORTAL_ID,
          formId: HUBSPOT_FORM_ID[LS.lang],
          target: formNodeElement,
          pageName: getUrlPageNameFormatted() + document.title
        });
      }
    });
  },

  checkFormStep() {
    const hubspotForm = document.querySelector(FORM_WRAPPER_ID);

    if(hubspotForm) {
      const hubspotContext = hubspotForm.querySelector('input[name="hs_context"]').value;
      const hubspostContextJson = JSON.parse(hubspotContext);
      const formIsEmpty = hubspostContextJson.renderedFieldsIds.length <= 1;

      if(formIsEmpty) {
        console.log('[Hubspot embed]: Form is empty')
      }

      if(formIsEmpty && this.currenStep() != 'finish') {
        _setCookie(FORM_STEP_COOKIE, 'finish');
      }
    }
  },

  onSubmitForm() {
    if(this.currenStep()) {
      if(this.currenStep() == 1) {
        _setCookie(FORM_STEP_COOKIE, 'finish');
      }
    } else {
      const { data } = event.data;
      const userDataJson = {
        name: data.submissionValues.firstname
      };

      _setCookie(USER_DATA_COOKIE, userDataJson);
      _setCookie(FORM_STEP_COOKIE, 1);
    }
  },

  bindEvents(onReadyCallback, onSubmitCallback) {
    window.addEventListener("message", (event) => {
      if (
        event.data.type === "hsFormCallback" &&
        event.data.eventName === "onFormReady"
      ) {
        console.log('[Hubspot embed]: Form generated');

        this.checkFormStep();
        onReadyCallback && onReadyCallback();
      }

      if (
        event.data.type === "hsFormCallback" &&
        event.data.eventName === "onFormSubmitted"
      ) {
        document.dispatchEvent(HUBSPOT_FORM_SUBMIT_EVENT);
        console.log('[Hubspot embed]: Form submitted');

        this.onSubmitForm();
        onSubmitCallback && onSubmitCallback();
      }
    });
  },

  init(options = {}) {
    if(!HUBSPOT_FORM_ID[LS.lang]) {
      console.warn('[Missing ID] Hubspot form ID not found for: ' + LS.lang);
      return;
    }

    this.generateForm();
    this.bindEvents(
      options.hasOwnProperty('onReady') ? options.onReady : null,
      options.hasOwnProperty('onSubmit') ? options.onSubmit : null
    );
  }
};

